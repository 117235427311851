import React, { useEffect, useState, Fragment } from 'react'
import styled from 'styled-components'
import Header from '../components/Header'
import {
  Button,
  Card,
  CardContent,
  CircularProgress,
  Container,
  FormControl,
  Grid,
  Paper,
  Snackbar,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
} from '@material-ui/core'
import CardTitle from '../components/CardTitle'
import Body from '../components/Body'
import Footer from '../components/Footer'
import Toast from '../components/Toast'
import useSeedTournament from '../hooks/useSeedTournament'
import text from '../assets/json/text.json'
import StyledCard from '../components/StyledCard'
import SearchBar from 'material-ui-search-bar'
import ToastSpinner from '../components/ToastSpinner'
import useTitle from '../hooks/useTitle'

const FormControlStyled = styled(FormControl)`
  width: 100%;
`

export default function Seed() {
  useTitle('SmashTube - Seed')

  const [tournament, setTournament] = useState('')
  const [
    seed,
    { data, loading, setLoading, message, setMessage },
  ] = useSeedTournament()

  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(15)
  const handleChangePage = (event, newPage) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10))
    setPage(0)
  }
  return (
    <Fragment>
      <Header />
      <Body className={'body'}>
        <Container>
          <Grid container justifyContent={'center'} alignItems={'center'}>
            <Grid item xs={6}>
              <h1>Seed Tournament</h1>
            </Grid>
            <Grid item xs={6} style={{ textAlign: 'right' }}>
              <code style={{ fontSize: '8pt' }}>
                Bug Report / Feature Request
                <br />
                Email:{' '}
                <a
                  target="_blank"
                  className={'link'}
                  href={`mailto:${text.email}`}
                >
                  {text.email}
                </a>
              </code>
            </Grid>
          </Grid>
          <StyledCard>
            <CardContent>
              <FormControlStyled variant={'outlined'}>
                <TextField
                  id="tournament"
                  label="Tournament Name"
                  variant="outlined"
                  onChange={({ target: { value } }) => setTournament(value)}
                  value={tournament}
                />
              </FormControlStyled>
              <br />
              <p>
                <small>
                  Enter the name of your tournament that you would like to seed.
                  The name of the tournament can be taken from the URL. For
                  example, the following tournament{' '}
                  <code>https://start.gg/tournament/mega-smash-tuesdays-5</code>{' '}
                  will have a tournament name of{' '}
                  <code> mega-smash-tuesdays-5</code>. Seeding can be
                  completed/updated any time before the bracket begins.
                </small>
              </p>

              <br />
              <Button
                style={{ width: '100%' }}
                variant="contained"
                color="primary"
                disabled={tournament.length === 0}
                onClick={() => {
                  if (window.localStorage.getItem('API_KEY')) {
                    seed(tournament)
                  } else {
                    setMessage({
                      text: 'Start.gg API key is not set',
                      type: 'error',
                    })
                  }
                }}
              >
                Seed
              </Button>
            </CardContent>
          </StyledCard>

          <br />

          {/* Seed data */}
          <StyledCard>
            <CardContent>
              <CardTitle>Seeding Results</CardTitle>
              {message.type === 'error' && (
                <span style={{ color: 'red' }}>
                  {message.text}
                  <br />
                  <br />
                </span>
              )}

              {data?.tableData?.length ? (
                <TableContainer component={Paper}>
                  <Table aria-label="simple table" searc>
                    <TableHead>
                      <TableRow>
                        <TableCell>
                          <b>User</b>
                        </TableCell>
                        <TableCell align="right">
                          <b>Wins</b>
                        </TableCell>
                        <TableCell align="right">
                          <b>Losses</b>
                        </TableCell>
                        <TableCell align="right">
                          <b>Win Rate</b>
                        </TableCell>
                        <TableCell align="right">
                          <b>Seed #</b>
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {(rowsPerPage > 0
                        ? data.tableData.slice(
                            page * rowsPerPage,
                            page * rowsPerPage + rowsPerPage,
                          )
                        : data.tableData
                      ).map(({ userName, winRate, wins, losses }, idx) => (
                        <TableRow key={`${userName}-${winRate}`}>
                          <TableCell component="th" scope="row">
                            <b>{userName}</b>
                          </TableCell>
                          <TableCell align="right">{wins}</TableCell>
                          <TableCell align="right">{losses}</TableCell>
                          <TableCell align="right">
                            {winRate.toFixed(2)}%
                          </TableCell>
                          <TableCell align="right">
                            {idx + page * rowsPerPage + 1}
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                    <TableFooter>
                      <TableRow>
                        <TablePagination
                          rowsPerPageOptions={[
                            5,
                            10,
                            15,
                            25,
                            { label: 'All', value: -1 },
                          ]}
                          colSpan={3}
                          count={data.entrantData.length}
                          rowsPerPage={rowsPerPage}
                          page={page}
                          SelectProps={{
                            inputProps: { 'aria-label': 'rows per page' },
                            native: true,
                          }}
                          onPageChange={handleChangePage}
                          onRowsPerPageChange={handleChangeRowsPerPage}
                        />
                      </TableRow>
                    </TableFooter>
                  </Table>
                </TableContainer>
              ) : (
                <center>
                  <em className={'faded'}>Waiting to seed... </em>
                </center>
              )}
            </CardContent>
          </StyledCard>

          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
        </Container>
      </Body>
      <Toast
        message={
          <div>
            {message.type !== 'error' && <ToastSpinner />}{' '}
            <span
              style={{ verticalAlign: 'middle' }}
              dangerouslySetInnerHTML={{ __html: message.text }}
            />
          </div>
        }
        open={loading}
        autoHideDuration={15000}
        onClick={() => setLoading(false)}
      />
      <Footer />
    </Fragment>
  )
}
