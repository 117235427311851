import {
    alpha,
    AppBar,
    Button,
    Fade,
    Grid,
    IconButton,
    InputBase,
    makeStyles,
    Slide,
    Snackbar,
    TextField,
    Toolbar,
    Tooltip,
} from '@material-ui/core';
import SsbuIcon from '../assets/img/icon.png';
import SsbuIcon2 from '../assets/img/logo.gif';
import React, {useEffect, useState} from 'react';
import {useHistory} from 'react-router-dom';
import {useAppContext, smashggkey} from '../App.js';
import Toast from './Toast';
import ToastSpinner from './ToastSpinner';
import text from '../assets/json/text.json';

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    menuButton: {
        marginRight: theme.spacing(2),
    },
    title: {
        flexGrow: 1,
        display: 'none',
        [theme.breakpoints.up('sm')]: {
            display: 'block',
        },
    },
    api: {
        alignItems: 'flex-end',
        position: 'relative',
        borderRadius: theme.shape.borderRadius,
        backgroundColor: alpha(theme.palette.common.black, 0.15),
        '&:hover': {
            backgroundColor: alpha(theme.palette.common.black, 0.25),
        },
        marginLeft: 0,
        width: '350px',
        [theme.breakpoints.up('sm')]: {
            marginLeft: theme.spacing(1),
            width: '350px',
        },
    },
}));

const NavButton = ({name, path, tooltip, history}) => {
    let className = '';
    if (history?.location?.pathname === path) {
        className = 'link';
    }

    return (
        <Button color="inherit" onClick={() => history.push(path)}>
            <Tooltip title={tooltip}>
                <span className={className}>{name}</span>
            </Tooltip>
        </Button>
    );
};

export default function Header() {
    let history = useHistory();
    const classes = useStyles();
    const {apiKey, setApiKey} = useAppContext();
    const [showWarningToast, setShowWarningToast] = useState(false);

    const message = false;
    // const message = <>Overlay tab has been temporarily disabled.</>;

    const warningId = 'warning_shown_3';
    const warningMessage = <span>
        <b className={'link'}>Overlay tab has been disabled temporarily.</b>
        <br/>
        <span>If this effects you and would like more information, feel free to contact us at <a target="_blank" className={'link'} href={`mailto:${text.email}`}>{text.email}</a></span>. <i>Click here to remove this popup.</i>
    </span>;
    useEffect(() => {
        setShowWarningToast(window.localStorage.getItem(warningId) ?? false);
    }, []);

    useEffect(() => {
        window.localStorage.setItem('API_KEY', apiKey);
    }, [apiKey]);

    return (
        <AppBar position="static" className="header">
            <Toast
                message={warningMessage}
                open={!showWarningToast}
                autoHideDuration={120000}
                onClick={() => {
                    window.localStorage.setItem(warningId, 1);
                    setShowWarningToast(true);
                }}
            />

            <Toolbar>
                <Grid container justifyContent={'space-between'} alignItems={'center'}>
                    <Grid item xs={12} sm={6} style={{textAlign: 'left'}}>
                        <Grid container justifyContent={'flex-start'} alignItems={'center'}>
                            <Grid item lg={1} style={{userSelect: 'none'}}>
                                <img
                                    src={SsbuIcon2}
                                    width={50}
                                    height={50}
                                    style={{mixBlendMode: 'screen', marginLeft: '-10px'}}
                                    alt="SmashTube.ca icon"
                                />
                            </Grid>
                            <Grid item lg={2} style={{textAlign: 'center'}}>
                                <NavButton
                                    name={'Home'}
                                    path={'/'}
                                    history={history}
                                    tooltip="The homepage :)"
                                />
                            </Grid>
                            <Grid item lg={2} style={{textAlign: 'center'}}>
                                <NavButton
                                    name={'Upcoming'}
                                    path={'/upcoming'}
                                    history={history}
                                    tooltip="Quickly register for upcoming events"
                                />
                            </Grid>
                            <Grid item lg={2} style={{textAlign: 'center'}}>
                                <NavButton
                                    name={'Seed'}
                                    path={'/seed'}
                                    history={history}
                                    tooltip="Seed your tournaments"
                                />
                            </Grid>
                            <Grid item lg={2} style={{textAlign: 'center'}}>
                                <NavButton
                                    name={'Stats'}
                                    path={'/stats'}
                                    history={history}
                                    tooltip="Get tournament participant information (win/loss rates, primary character, etc)"
                                />
                            </Grid>
                            <Grid item lg={2} style={{textAlign: 'center'}}>
                                <Button color="inherit" onClick={() => {
                                }}>
                                    <Tooltip title={<span>
                    <b className={'link'}><u>TEMPORARILY DISABLED</u></b> <i>Check back soon.</i>
                    <br/>Build a custom or dynamic browser overlay for your streams.
                  </span>}>
                                        <span>Overlay</span>
                                    </Tooltip>
                                </Button>
                                {/*<NavButton*/}
                                {/*  name={'Overlay'}*/}
                                {/*  path={'/build'}*/}
                                {/*  history={history}*/}
                                {/*  tooltip="Build a custom or dynamic browser overlay for your streams."*/}
                                {/*/>*/}
                            </Grid>
                            {/* ! asdf TODO --  */}
                            {/* <Grid item lg={3}>
                                <NavButton name={"Statistics"} path={"/statistics"} history={history} />
                            </Grid>
                            <Grid item lg={3}>
                                <NavButton name={"Help"} path={"/help"} history={history} />
                            </Grid> */}
                        </Grid>
                    </Grid>
                    {history?.location?.pathname === '/seed' && (
                        <Grid item xs={12} sm={6} style={{textAlign: 'right'}}>
                            <Tooltip title="Your start.gg API key. This is used for fetching data from start.gg.">
                                <TextField
                                    style={{width: '100%', color: 'white'}}
                                    id="API_KEY"
                                    label="start.gg API Key"
                                    variant="outlined"
                                    type="password"
                                    autoComplete="false"
                                    onChange={({target: {value}}) => setApiKey(value)}
                                    value={apiKey === smashggkey ? '' : apiKey}
                                />
                            </Tooltip>
                        </Grid>
                    )}
                    {message && (
                        <Grid item xs={12}>
                            <hr/>
                            <center
                                style={{
                                    backgroundColor: '#d0810011',
                                    borderRadius: '4px',
                                    padding: '4px 0',
                                }}
                            >
                                {message}
                            </center>
                        </Grid>
                    )}
                </Grid>
                {/*<NavButton name={'About Us'} path={'/aboutUs'} history={history}/>*/}
            </Toolbar>
        </AppBar>
    );
}
