import ApolloClient from 'apollo-boost'
import { UPCOMING_SSBU_TOURNAMENTS } from '../query'
import { useEffect, useState } from 'react'
import gql from 'graphql-tag'
import moment from 'moment'
const client = new ApolloClient({
  uri: 'https://api.start.gg/gql/alpha',
  request: (operation) => {
    operation.setContext({
      headers: {
        authorization: `Bearer ${localStorage.getItem('API_KEY')}`,
      },
    })
  },
})

export default function useUpcomingTournaments() {
  const [data, setData] = useState()
  const [message, setMessage] = useState({
    text: 'Fetching upcoming tournaments...',
    type: 'success',
  })
  const [loading, setLoading] = useState(false)
  const [done, setDone] = useState(false)

  useEffect(() => {
    console.log('upcoming...')

    client
      .query({
        query: UPCOMING_SSBU_TOURNAMENTS,
        variables: {
          afterDate: Math.floor(new Date().getTime() / 1000),
        },
      })
      .then(
        (res) => {
          res = res?.data?.tournaments?.nodes || []
          setData(
            res.map(
              ({ startAt, endAt, eventRegistrationClosesAt, ...other }) => ({
                startAt: startAt ? moment.unix(startAt).format('LLL') : '',
                endAt: endAt ? moment.unix(endAt).format('LLL') : '',
                eventRegistrationClosesAt: eventRegistrationClosesAt
                  ? moment.unix(eventRegistrationClosesAt).format('LLL')
                  : '',
                ...other,
              }),
            ),
          )
        },
        (err) => console.log(err),
      )
  }, [])

  return [
    data,
    {
      setLoading,
      loading,
      message,
      setMessage,
    },
  ]
}
