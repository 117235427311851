import {
  Button,
  Grid,
  Tooltip,
  useMediaQuery,
  IconButton,
} from "@material-ui/core";
import React from "react";
import PayPalDonateButton from "./PayPalDonateButton";
import text from "../assets/json/text.json";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import PayPalDonateImage from "./PayPalDonateImage";
import {
  Reddit,
  Twitter,
  YouTube,
  Star,
  Mic,
  PictureInPicture,
  BookTwoTone,
} from "@material-ui/icons";

import * as T from "@material-ui/icons";
console.log(T);
export default function Footer() {
  let history = useHistory();
  const isBigScreen = useMediaQuery("(min-width:1100px)");

  return (
    <div className={"footer"}>
      <Grid
        container
        spacing={0}
        style={{ height: "100%" }}
        alignItems={"center"}
      >
        <Grid item xs={12} md={12}>
          <div className={"small"}>
            Feedback / Need To Contact Us? &nbsp;&nbsp;&nbsp;
            <b>
              <a
                target="_blank"
                className={"link small"}
                href={`mailto:${text.email}`}
              >
                {text.email}
              </a>
            </b>
          </div>

          <div>
            <a
              className={"link small"}
              onClick={() => history.push("/privacyPolicy")}
            >
              Privacy Policy
            </a>
            &nbsp;&nbsp;
            <a
              className={"link small"}
              onClick={() => history.push("/termsAndConditions")}
            >
              Terms and Conditions
            </a>
            &nbsp;&nbsp;
            <a
              className={"link small"}
              onClick={() => history.push("/changelog")}
            >
              Changelog
            </a>
          </div>
          <Grid
            spacing={0}
            container
            direction={"row"}
            justifyContent={"center"}
            alignItems={"center"}
          >
            <Grid item>
              <Tooltip
                title={
                  <span>
                    Support <b>YouToogle</b>!<br />
                    Give them a like or follow if you've enjoyed the site :D
                  </span>
                }
                placement="bottom"
              >
                <IconButton
                  onClick={() =>
                    window.open("https://www.youtube.com/c/YouToogle")
                  }
                >
                  <YouTube
                    style={{
                      verticalAlign: "middle",
                      fontSize: "16px",
                      color: "rgb(255, 0,0)",
                    }}
                  />
                </IconButton>
              </Tooltip>
              <Tooltip title="@SSmashtube" placement="bottom">
                <IconButton
                  onClick={() => window.open("https://twitter.com/SSmashtube")}
                >
                  <Twitter
                    style={{
                      verticalAlign: "middle",
                      fontSize: "16px",
                      color: "rgb(29, 155, 240)",
                    }}
                  />
                </IconButton>
              </Tooltip>
            </Grid>
            <Grid item>
              <Tooltip title="r/Socialism" placement="bottom">
                <IconButton
                  onClick={() =>
                    window.open("https://www.reddit.com/r/Socialism/")
                  }
                >
                  <Star
                    style={{
                      verticalAlign: "middle",
                      fontSize: "16px",
                      color: "#ff4500",
                    }}
                  />
                </IconButton>
              </Tooltip>
            </Grid>
            <Grid item>
              <Tooltip title="@KyleKulinski" placement="bottom">
                <IconButton
                  onClick={() => window.open("https://x.com/KyleKulinski")}
                >
                  <Mic
                    style={{
                      verticalAlign: "middle",
                      fontSize: "16px",
                      color: "#24ff33",
                    }}
                  />
                </IconButton>
              </Tooltip>
            </Grid>
            <Grid item>
              <Tooltip title="hasanabi" placement="bottom">
                <IconButton
                  onClick={() => window.open("https://www.twitch.tv/hasanabi")}
                >
                  <PictureInPicture
                    style={{
                      verticalAlign: "middle",
                      fontSize: "16px",
                      color: "#6441a5",
                    }}
                  />
                </IconButton>
              </Tooltip>
            </Grid>
            <Grid item>
              <Tooltip title="Ground News" placement="bottom">
                <IconButton onClick={() => window.open("https://ground.news/")}>
                  <BookTwoTone
                    style={{
                      verticalAlign: "middle",
                      fontSize: "16px",
                      color: "#b0b0b0",
                    }}
                  />
                </IconButton>
              </Tooltip>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
}
