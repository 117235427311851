import ApolloClient from 'apollo-boost'
import {
  SEED_GET_ENTRANT_DATA,
  SEED_GET_SEED_DATA,
  SEED_MUTATE_PHASE_SEEDING,
} from '../query'
import { useEffect, useState } from 'react'
import gql from 'graphql-tag'

const client = new ApolloClient({
  uri: 'https://api.start.gg/gql/alpha',
  request: (operation) => {
    operation.setContext({
      headers: {
        authorization: `Bearer ${localStorage.getItem('API_KEY')}`,
      },
    })
  },
})
const pgStatsClient = new ApolloClient({
  uri: 'https://api.pgstats.com/graphql',
})

export default function useSeedTournament() {
  const [data, setData] = useState()
  const [message, setMessage] = useState({
    text: 'Generating seed data',
    type: 'success',
  })
  const [loading, setLoading] = useState(false)
  const [done, setDone] = useState(false)

  const seed = async (tournamentName) => {
    let d = {
      entrantData: [],
      seedData: [],
      tableData: [],
      phaseId: null,
      seedMapping: null,
    }

    // Get entrant data
    try {
      setMessage({ text: 'Fetching entrant data...', type: 'success' })
      d.entrantData = (
        await client.query({
          query: SEED_GET_ENTRANT_DATA,
          variables: {
            slug: tournamentName,
          },
        })
      )?.data?.tournament?.events?.[0]?.entrants?.nodes
    } catch (e) {
      setMessage({ text: 'Could not get entrant data...', type: 'error' })
      console.error('Could not get entrant data...', e)
      return
    }
    if (!d?.entrantData?.length) {
      setMessage({ text: 'No entrants in event...', type: 'error' })
      console.error('No entrants in event...')
      return
    }

    // Get seed data
    try {
      setMessage({ text: 'Retrieving current seed data...', type: 'success' })
      d.seedData = await client.query({
        query: SEED_GET_SEED_DATA,
        variables: {
          slug: tournamentName,
        },
      })
    } catch (e) {
      setMessage({ text: 'Could not get seed data...', type: 'error' })
      console.error('Could not get seed data...')
      return
    }
    if (!d.seedData) {
      setMessage({ text: 'Could not get seed data...', type: 'error' })
      console.error('Could not get seed data...')
      return
    }

    d.phaseId = d.seedData.data?.tournament?.events?.[0]?.phases?.[0]?.id
    d.seedData = (d.seedData.data?.tournament?.events?.[0]?.phases?.[0]?.seeds?.nodes).reduce(
      (acc, row) => ({
        ...acc,
        [row?.entrant?.participants[0]?.user?.id]: {
          seedId: row?.id,
          seedNum: row?.seedNum,
          totalPosition: 0,
          totalEntrants: 0,
        },
      }),
      {},
    )

    let gqlQuery = ''
    for (let entrant of d.entrantData) {
      let PgId = `S${entrant?.participants?.[0]?.player?.id}`
      gqlQuery += `
                    ${PgId}: allPlayers(condition: {id: "${PgId}"}, first: 1) {
                        nodes {
                            setWins         #int 
                            setLosses       #int
                        }
                    }
                `
    }
    gqlQuery = gql`query MyQuery {
                ${gqlQuery}
            }`

    const pgStats = await pgStatsClient.query({
      query: gqlQuery,
      variables: {},
    })

    await d.entrantData.map(async (entrant) => {
      let userId = entrant?.participants?.[0]?.user?.id
      let playerId = entrant?.participants?.[0]?.player?.id
      let playerPgId = `S${playerId}`
      let gamerTag = entrant?.participants?.[0]?.player?.gamerTag

      setMessage({
        text: `Calculating data for <i>${gamerTag}</i>...`,
        type: 'success',
      })

      let wins = pgStats?.data?.[playerPgId]?.nodes?.[0]?.setWins
      let losses = pgStats?.data?.[playerPgId]?.nodes?.[0]?.setLosses || 1

      let curWinRate = (wins / (losses + wins)) * 100
      d.seedData[userId].winRate = curWinRate
      // Sort table mapping data
      d.tableData.push({
        userName: gamerTag,
        winRate: curWinRate,
        wins: wins,
        losses: losses,
      })
      d.tableData.sort((a, b) => b.winRate - a.winRate)
      // Sort seed mapping data
      d.seedMapping = Object.entries(d.seedData)
        .map(([, { seedId, seedNum, winRate }]) => ({
          seedId,
          seedNum,
          winRate,
        }))
        .sort((a, b) => b.winRate - a.winRate)
      setDone(!done)
    })
    setData(d)
  }

  useEffect(() => {
    if (
      data?.entrantData &&
      data?.seedMapping?.length === data?.entrantData?.length
    ) {
      try {
        client
          .mutate({
            mutation: SEED_MUTATE_PHASE_SEEDING,
            variables: {
              phaseId: data.phaseId,
              seedMapping: data.seedMapping.map(({ seedId, seedNum }) => ({
                seedId,
                seedNum,
              })),
            },
          })
          .then(
            ({
              data: {
                updatePhaseSeeding: { id },
              },
            }) => {
              if (id) {
                setLoading(false)
              } else {
                console.error(
                  'Could not update event seeds. Did the event already start?',
                )
              }
            },
            (err) => {
              console.error(
                'Could not update event seeds. Did the event already start?',
                err,
              )
              setMessage({
                text:
                  'Could not update event seeds. Did the event already start?',
                type: 'error',
              })
              setLoading(false)
            },
          )
      } catch (e) {
        console.error(e)
      }
    }
  }, [data, done])

  return [
    (t) => {
      setLoading(true)
      seed(t)
    },
    {
      data,
      setLoading,
      loading,
      message,
      setMessage,
    },
  ]
}
