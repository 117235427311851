import ApolloClient from 'apollo-boost'
import {
  SEED_GET_ENTRANT_DATA,
  SEED_GET_SEED_DATA,
  SEED_MUTATE_PHASE_SEEDING,
} from '../query'
import { useEffect, useState } from 'react'
import gql from 'graphql-tag'

const client = new ApolloClient({
  uri: 'https://api.start.gg/gql/alpha',
  request: (operation) => {
    operation.setContext({
      headers: {
        authorization: `Bearer ${localStorage.getItem('API_KEY')}`,
      },
    })
  },
})
const pgStatsClient = new ApolloClient({
  uri: 'https://api.pgstats.com/graphql',
})

export default function useTournamentStats() {
  const [data, setData] = useState()
  const [message, setMessage] = useState({
    text: 'Generating tournament stats data',
    type: 'success',
  })
  const [loading, setLoading] = useState(false)
  const [done, setDone] = useState(false)

  const seed = async (tournamentName) => {
    let d = {
      entrantData: [],
      tableData: [],
    }

    // Get entrant data
    try {
      setMessage({ text: 'Fetching entrant data...', type: 'success' })
      d.entrantData = (
        await client.query({
          query: SEED_GET_ENTRANT_DATA,
          variables: {
            slug: tournamentName,
          },
        })
      )?.data?.tournament?.events?.[0]?.entrants?.nodes
    } catch (e) {
      setMessage({ text: 'Could not get entrant data...', type: 'error' })
      console.error('Could not get entrant data...', e)
      return
    }
    if (!d.entrantData.length) {
      setMessage({ text: 'No entrants in event...', type: 'error' })
      console.error('No entrants in event...')
      return
    }

    // Build GraphQL query . Save pgstats some data
    let gqlQuery = ''
    for (let entrant of d.entrantData) {
      let PgId = `S${entrant?.participants?.[0]?.player?.id}`
      gqlQuery += `
                ${PgId}: allPlayers(condition: {id: "${PgId}"}, first: 1) {
                    nodes {
                        id              #string S##### (used for linking to PGStats)
                        tag             #string (used for linking to PGStats)
                        bio             #string         
                        setWins         #int 
                        setLosses       #int
                        social          #JSON ('TWITCH', 'DISCORD', 'TWITTER') 
                        images          #JSON (profile.url, or profile.banner (fallback))
                        gameWins        #int
                        gameLosses      #int
                        mains           #JSON ('all' key)
                    }
                }
            `
    }
    gqlQuery = gql`query MyQuery {
            ${gqlQuery}
        }`

    const pgStats = await pgStatsClient.query({
      query: gqlQuery,
      variables: {},
    })

    for (let entrant of d.entrantData) {
      let userId = entrant?.participants?.[0]?.user?.id
      let playerId = entrant?.participants?.[0]?.player?.id
      let playerPGId = `S${playerId}`
      let gamerTag = entrant?.participants?.[0]?.player?.gamerTag

      let social = JSON.parse(
        pgStats?.data?.[playerPGId]?.nodes?.[0]?.social || '{}',
      )
      let images = JSON.parse(
        pgStats?.data?.[playerPGId]?.nodes?.[0]?.images || '{}',
      )
      let mains = JSON.parse(
        pgStats?.data?.[playerPGId]?.nodes?.[0]?.mains || '{}',
      )

      setMessage({
        text: `Setting data for <i>${gamerTag}</i>...`,
        type: 'success',
      })

      d.tableData.push({
        userName: gamerTag,
        id: pgStats?.data[playerPGId].nodes?.[0]?.id, // used for linking to PGstats
        tag: pgStats?.data[playerPGId].nodes?.[0]?.tag, // used for linking to PGstats
        bio: pgStats?.data[playerPGId].nodes?.[0]?.bio,
        winRate:
          (pgStats?.data?.[playerPGId]?.nodes?.[0]?.setWins /
            ((pgStats?.data?.[playerPGId]?.nodes?.[0]?.setWins || 0) +
              (pgStats?.data?.[playerPGId]?.nodes?.[0]?.setLosses || 0) || 0)) *
          100,
        wins: pgStats?.data?.[playerPGId]?.nodes?.[0]?.setWins || 0,
        losses: pgStats?.data?.[playerPGId]?.nodes?.[0]?.setLosses || 0,
        character: mains?.all,
        profile: images?.profile?.url || false,
        twitch: social?.TWITCH || false,
        discord: social?.DISCORD || false,
        twitter: social?.TWITTER || false,
      })
      d.tableData.sort((a, b) => b.winRate - a.winRate)
    }

    setData(d)
    setDone(true)
  }

  useEffect(() => {
    if (data?.tableData?.length === data?.entrantData?.length) {
      setLoading(false)
      setMessage({ text: `Done!`, type: 'success' })
    }
  }, [data, done])

  return [
    (t) => {
      setLoading(true)
      seed(t)
    },
    {
      data,
      setLoading,
      loading,
      message,
      setMessage,
    },
  ]
}
