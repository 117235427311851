import React, { useContext, useEffect, useState, createContext } from "react";
import "./App.scss";
import { HashRouter, Route, Switch } from "react-router-dom";
import Build from "./route/Build";
import Overlay from "./route/Overlay";
import Seed from "./route/Seed";
import Upcoming from "./route/Upcoming";
import Home from "./route/Home";
import AboutUs from "./route/AboutUs";
import { createTheme, ThemeProvider, useMediaQuery } from "@material-ui/core";
import Help from "./route/Help";
import PrivacyPolicy from "./route/PrivacyPolicy";
import TermsAndConditions from "./route/TermsAndConditions";
import Stats from "./route/Stats";
import Analytics from "react-router-ga";
import Changelog from "./route/Changelog";

const AppContext = createContext();

export const smashggkey = "0257e62960028346199ecfaa0058e15e";
export const useAppContext = () => {
  return useContext(AppContext);
};

function App() {
  const prefersDarkMode = useMediaQuery("(prefers-color-scheme: dark)");

  // Update the key for 2025
  let key = window.localStorage.getItem("API_KEY");
  let hasKeyBeenUpdated = window.localStorage.getItem("KEY_UPDATED_2025");
  if (!hasKeyBeenUpdated) {
    window.localStorage.setItem("KEY_UPDATED_2025", 1);
    window.localStorage.setItem("API_KEY", smashggkey);
    key = smashggkey;
  }

  const [apiKey, setApiKey] = useState(key?.length == 32 ? key : smashggkey); // elevatedkey
  const [tournament, setTournament] = useState(
    window.localStorage.getItem("LAST_TOURNAMENT_SEARCH") || "",
  );

  useEffect(() => {
    window.localStorage.setItem("LAST_TOURNAMENT_SEARCH", tournament);
  }, [tournament]);

  const theme = React.useMemo(
    () =>
      createTheme({
        palette: {
          type: "dark",
          primary: {
            main: "#2d2d2d",
            contrastText: "#ffffff", // Nav bar links
          },
          secondary: {
            main: "#2d2d2d", // Radio etc
            main: "#f57c00",
            contrastText: "#f57c00",
          },
          contrastThreshold: 3,
          tonalOffset: 0.2,
        },
      }),
    [prefersDarkMode],
  );

  return (
    <AppContext.Provider
      value={{ apiKey, setApiKey, tournament, setTournament }}
    >
      <ThemeProvider theme={theme}>
        <HashRouter>
          <Analytics id="UA-141270533-2">
            <Route path="/" component={Home} exact />
            <Route path="/seed" component={Seed} />
            <Route path="/upcoming" component={Upcoming} />

            {/* TODO: fix the following */}
            <Route path="/build" component={Build} />
            <Route path="/overlay" component={Overlay} />

            {/*<Route path="/aboutUs" component={AboutUs}/>*/}
            <Route path="/stats" component={Stats} />
            {/* <Route path="/help" component={Help} /> */}
            {/* hidden tabs */}
            <Route path="/privacyPolicy" component={PrivacyPolicy} />
            <Route path="/termsAndConditions" component={TermsAndConditions} />
            <Route path="/changelog" component={Changelog} />
          </Analytics>
        </HashRouter>
      </ThemeProvider>
    </AppContext.Provider>
  );
}

export default App;
