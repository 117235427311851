import React, { useEffect, useState, Fragment } from 'react'
import styled from 'styled-components'
import Header from '../components/Header'
import {
  Button,
  Card,
  CardContent,
  CircularProgress,
  Container,
  FormControl,
  Grid,
  Paper,
  Snackbar,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Tooltip,
  useMediaQuery,
} from '@material-ui/core'
import CardTitle from '../components/CardTitle'
import Body from '../components/Body'
import Footer from '../components/Footer'
import Toast from '../components/Toast'
import text from '../assets/json/text.json'
import StyledCard from '../components/StyledCard'
import useTournamentStats from '../hooks/useTournamentStats'
import { getCharacterName, getCharacterSrc } from '../util/util'
import SearchBar from 'material-ui-search-bar'
import ToastSpinner from '../components/ToastSpinner'
import ApolloClient from 'apollo-boost'
import gql from 'graphql-tag'
import useTitle from '../hooks/useTitle'
import { useAppContext } from '../App'
import StyledHr from '../components/StyledHr'

const FormControlStyled = styled(FormControl)`
  width: 100%;
`

export default function Stats() {
  useTitle('SmashTube - Statistics')
  const useFullSizeDataTable = useMediaQuery('(min-width:550px)')

  const { tournament, setTournament } = useAppContext()
  const [
    getStats,
    { data, loading, setLoading, message, setMessage },
  ] = useTournamentStats()
  const [page, setPage] = useState(0)

  const [rowsPerPage, setRowsPerPage] = useState(15)
  const handleChangePage = (event, newPage) => {
    setPage(newPage)
  }
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10))
    setPage(0)
  }

  // review -search things
  const [searched, setSearched] = useState('')
  const [rows, setRows] = useState([])
  const cancelSearch = () => {
    setSearched('')
    requestSearch(searched)
  }
  const requestSearch = (searchedVal) => {
    const filteredRows = data.tableData.filter((row) => {
      return (
        (row.tag || '').toLowerCase().includes(searchedVal.toLowerCase()) ||
        getCharacterName(row.character)
          .toLowerCase()
          .includes(searchedVal.toLowerCase())
      )
    })
    setPage(0)
    setRows(filteredRows)
  }

  useEffect(() => {
    setRows(data?.tableData || [])
  }, [data])
  return (
    <Fragment>
      <Header />
      <Body className={'body'}>
        <Container>
          <Grid container justifyContent={'center'} alignItems={'center'}>
            <Grid item xs={6}>
              <h1>Participant Statistics</h1>
            </Grid>
            <Grid item xs={6} style={{ textAlign: 'right' }}>
              <code style={{ fontSize: '8pt' }}>
                Bug Report / Feature Request
                <br />
                Email:{' '}
                <a
                  target="_blank"
                  className={'link'}
                  href={`mailto:${text.email}`}
                >
                  {text.email}
                </a>
              </code>
            </Grid>
          </Grid>
          <StyledCard>
            <CardContent>
              <FormControlStyled variant={'outlined'}>
                <TextField
                  id="tournament"
                  label="Tournament Name"
                  variant="outlined"
                  onChange={({ target: { value } }) => setTournament(value)}
                  value={tournament}
                />
              </FormControlStyled>
              <br />
              <p>
                <small>
                  Enter the name of your tournament that you would like to
                  retrieve participant statistics for. This will return
                  participants; profile picture, favorite character, gamer tag,
                  socials, win/loss rates, and suggested seed #. The name of the
                  tournament can be taken from the URL. For example, the
                  following tournament{' '}
                  <code>https://start.gg/tournament/mega-smash-tuesdays-5</code>{' '}
                  will have a tournament name of{' '}
                  <code> mega-smash-tuesdays-5</code>.
                </small>
              </p>

              <br />
              <Button
                style={{ width: '100%' }}
                variant="contained"
                color="primary"
                disabled={tournament.length === 0}
                onClick={() => {
                  if (window.localStorage.getItem('API_KEY')) {
                    getStats(tournament)
                  } else {
                    setMessage({
                      text: 'Start.gg API key is not set',
                      type: 'error',
                    })
                  }
                }}
              >
                Get Statistics
              </Button>
            </CardContent>
          </StyledCard>

          <br />

          {/* Seed data */}
          <StyledCard>
            <CardContent>
              <CardTitle>Tournament Participant Results</CardTitle>
              {data?.tableData?.length ? (
                <>
                  <SearchBar
                    value={searched}
                    onChange={(searchVal) => requestSearch(searchVal)}
                    onCancelSearch={() => cancelSearch()}
                  />
                  <br />
                  <TableContainer component={Paper}>
                    <Table aria-label="simple table">
                      <TableHead>
                        <TableRow>
                          {useFullSizeDataTable ? (
                            <>
                              <TableCell align="left" style={{ width: '30px' }}>
                                {/* PROFILE PHOTO */}
                              </TableCell>
                              <TableCell align="left" style={{ width: '30px' }}>
                                {/* <b>Favorite Character</b> */}
                              </TableCell>
                              <TableCell align="left">
                                <b>Gamer Tag</b>
                              </TableCell>
                              <TableCell align="right">
                                <b>Wins</b>
                              </TableCell>
                              <TableCell align="right">
                                <b>Losses</b>
                              </TableCell>
                              <TableCell align="right">
                                <b>Win Rate</b>
                              </TableCell>
                              <TableCell align="right">
                                <b>Seed #</b>
                              </TableCell>
                            </>
                          ) : (
                            <>
                              <TableCell
                                align="left"
                                style={{ width: '25px', margin: 0 }}
                              >
                                {/* PROFILE PHOTO */}
                              </TableCell>
                              <TableCell align="left" style={{ width: '30px' }}>
                                {/* <b>Favorite Character</b> */}
                              </TableCell>
                              <TableCell align="left">
                                <b>Info</b>
                              </TableCell>
                            </>
                          )}
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {(rowsPerPage > 0
                          ? rows.slice(
                              page * rowsPerPage,
                              page * rowsPerPage + rowsPerPage,
                            )
                          : rows
                        ).map(
                          (
                            {
                              userName,
                              profile,
                              winRate,
                              wins,
                              losses,
                              character,
                              twitch,
                              discord,
                              twitter,
                              tag,
                              id,
                              bio,
                            },
                            idx,
                          ) => (
                            <TableRow key={`${userName}-${winRate}`}>
                              {useFullSizeDataTable ? (
                                <>
                                  <TableCell
                                    component="th"
                                    scope="row"
                                    style={{ width: '30px' }}
                                  >
                                    {profile ? (
                                      <img
                                        style={{
                                          width: '25px',
                                          height: '25px',
                                          verticalAlign: 'middle',
                                          borderRadius: '4px',
                                        }}
                                        src={profile}
                                      />
                                    ) : (
                                      <img
                                        style={{
                                          width: '25px',
                                          height: '25px',
                                          verticalAlign: 'middle',
                                          borderRadius: '4px',
                                        }}
                                        src={
                                          'https://images.smash.gg/images/character/1746/image-485930109cf8a08e1b05d21071a037e9.png'
                                        }
                                      />
                                    )}{' '}
                                    {/* RANDOM CHARACTER */}
                                  </TableCell>
                                  <TableCell
                                    align="center"
                                    style={{ width: '30px' }}
                                  >
                                    {character ? (
                                        <Tooltip title={getCharacterName(character)}>
                                          <img
                                              style={{
                                                width: '25px',
                                                height: '25px',
                                                verticalAlign: 'middle',
                                                borderRadius: '4px',
                                              }}
                                              src={getCharacterSrc(character)}
                                          />
                                        </Tooltip>
                                    ) : (
                                        <Tooltip title={"Random"}>
                                          <img
                                              style={{
                                                width: '25px',
                                                height: '25px',
                                                verticalAlign: 'middle',
                                                borderRadius: '4px',
                                              }}
                                              src={
                                                'https://images.smash.gg/images/character/1746/image-485930109cf8a08e1b05d21071a037e9.png'
                                              }
                                          />
                                        </Tooltip>
                                    )}{' '}
                                    {/* RANDOM CHARACTER */}
                                  </TableCell>
                                  <TableCell component="th" scope="row">
                                    <Tooltip
                                      title={
                                        <span>
                                          <strong>Bio</strong>:{' '}
                                          {bio ? bio : '--'}
                                        </span>
                                      }
                                      interactive
                                    >
                                      <b>{userName}</b>
                                    </Tooltip>
                                    <>
                                      &nbsp;&nbsp;
                                      <Tooltip title={'PG Stats'} interactive>
                                        <a
                                          href={`https://www.pgstats.com/ultimate/player/${tag}?id=${id}`}
                                          target={'_blank'}
                                        >
                                          <img
                                            src={
                                              'https://i.ibb.co/DbGgDvQ/pg.png'
                                            }
                                            style={{
                                              width: '10px',
                                              height: '10px',
                                            }}
                                          />
                                        </a>
                                      </Tooltip>
                                    </>
                                    {twitch && (
                                      <>
                                        &nbsp;&nbsp;
                                        <Tooltip title={twitch} interactive>
                                          <a
                                            href={`https://twitch.tv/${twitch}`}
                                            target={'_blank'}
                                          >
                                            <img
                                              src={
                                                'https://i.ibb.co/VN8rdt6/twitch.png'
                                              }
                                              style={{
                                                width: '10px',
                                                height: '10px',
                                              }}
                                            />
                                          </a>
                                        </Tooltip>
                                      </>
                                    )}
                                    {twitter && (
                                      <>
                                        &nbsp;&nbsp;
                                        <Tooltip title={twitter} interactive>
                                          <a
                                            href={`https://twitter.com/${twitter}`}
                                            target={'_blank'}
                                          >
                                            <img
                                              src={
                                                'https://i.ibb.co/DCPpjYz/twitter.png'
                                              }
                                              style={{
                                                width: '10px',
                                                height: '10px',
                                              }}
                                            />
                                          </a>
                                        </Tooltip>
                                      </>
                                    )}
                                    {discord && (
                                      <>
                                        &nbsp;&nbsp;
                                        <Tooltip title={discord} interactive>
                                          <img
                                            src={
                                              'https://i.ibb.co/vBJGW8g/discord.png'
                                            }
                                            style={{
                                              width: '10px',
                                              height: '10px',
                                            }}
                                          />
                                        </Tooltip>
                                      </>
                                    )}
                                  </TableCell>
                                  <TableCell align="right">{wins}</TableCell>
                                  <TableCell align="right">{losses}</TableCell>
                                  <TableCell align="right">
                                    {winRate.toFixed(2)}%
                                  </TableCell>
                                  <TableCell align="right">
                                    {idx + page * rowsPerPage + 1}
                                  </TableCell>
                                </>
                              ) : (
                                <>
                                  <TableCell
                                    align="center"
                                    style={{
                                      width: '25px',
                                      margin: '0',
                                      padding: 0,
                                    }}
                                  >
                                    {profile ? (
                                      <img
                                        style={{
                                          width: '50px',
                                          height: '50px',
                                          verticalAlign: 'middle',
                                          borderRadius: '4px',
                                        }}
                                        src={profile}
                                      />
                                    ) : (
                                      <img
                                        style={{
                                          width: '50px',
                                          height: '50px',
                                          verticalAlign: 'middle',
                                          borderRadius: '4px',
                                        }}
                                        src={
                                          'https://images.smash.gg/images/character/1746/image-485930109cf8a08e1b05d21071a037e9.png'
                                        }
                                      />
                                    )}{' '}
                                    {/* RANDOM CHARACTER */}
                                  </TableCell>
                                  <TableCell component="th" scope="row">
                                    {character ? (
                                        <Tooltip title={getCharacterName(character)}>
                                          <img
                                              style={{
                                                width: '25px',
                                                height: '25px',
                                                verticalAlign: 'middle',
                                                borderRadius: '4px',
                                              }}
                                              src={getCharacterSrc(character)}
                                          />
                                        </Tooltip>
                                    ) : (
                                        <Tooltip title={"Random"}>
                                          <img
                                              style={{
                                                width: '25px',
                                                height: '25px',
                                                verticalAlign: 'middle',
                                                borderRadius: '4px',
                                              }}
                                              src={
                                                'https://images.smash.gg/images/character/1746/image-485930109cf8a08e1b05d21071a037e9.png'
                                              }
                                          />
                                        </Tooltip>
                                    )}
                                    <Tooltip
                                      title={
                                        <span>
                                          <strong>Bio</strong>:{' '}
                                          {bio ? bio : '--'}
                                        </span>
                                      }
                                      interactive
                                    >
                                      <b>{userName}</b>
                                    </Tooltip>
                                    <br />
                                    <>
                                      &nbsp;&nbsp;
                                      <Tooltip title={'PG Stats'} interactive>
                                        <a
                                          href={`https://www.pgstats.com/ultimate/player/${tag}?id=${id}`}
                                          target={'_blank'}
                                        >
                                          <img
                                            src={
                                              'https://i.ibb.co/DbGgDvQ/pg.png'
                                            }
                                            style={{
                                              width: '10px',
                                              height: '10px',
                                            }}
                                          />
                                        </a>
                                      </Tooltip>
                                    </>
                                    {twitch && (
                                      <>
                                        &nbsp;&nbsp;
                                        <Tooltip title={twitch} interactive>
                                          <a
                                            href={`https://twitch.tv/${twitch}`}
                                            target={'_blank'}
                                          >
                                            <img
                                              src={
                                                'https://i.ibb.co/VN8rdt6/twitch.png'
                                              }
                                              style={{
                                                width: '10px',
                                                height: '10px',
                                              }}
                                            />
                                          </a>
                                        </Tooltip>
                                      </>
                                    )}
                                    {twitter && (
                                      <>
                                        &nbsp;&nbsp;
                                        <Tooltip title={twitter} interactive>
                                          <a
                                            href={`https://twitter.com/${twitter}`}
                                            target={'_blank'}
                                          >
                                            <img
                                              src={
                                                'https://i.ibb.co/DCPpjYz/twitter.png'
                                              }
                                              style={{
                                                width: '10px',
                                                height: '10px',
                                              }}
                                            />
                                          </a>
                                        </Tooltip>
                                      </>
                                    )}
                                    {discord && (
                                      <>
                                        &nbsp;&nbsp;
                                        <Tooltip title={discord} interactive>
                                          <img
                                            src={
                                              'https://i.ibb.co/vBJGW8g/discord.png'
                                            }
                                            style={{
                                              width: '10px',
                                              height: '10px',
                                            }}
                                          />
                                        </Tooltip>
                                      </>
                                    )}
                                  </TableCell>
                                  <TableCell
                                    align="left"
                                    style={{ padding: 0, margin: 0 }}
                                  >
                                    <b>Wins:</b> {wins}
                                    <StyledHr />
                                    <b>Losses:</b> {losses}
                                    <StyledHr />
                                    <b>Win Rate:</b> {winRate.toFixed(2)}%
                                  </TableCell>
                                </>
                              )}
                            </TableRow>
                          ),
                        )}
                      </TableBody>
                      <TableFooter>
                        <TableRow>
                          <TablePagination
                            rowsPerPageOptions={[
                              5,
                              10,
                              15,
                              25,
                              { label: 'All', value: -1 },
                            ]}
                            colSpan={3}
                            count={rows.length}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            SelectProps={{
                              inputProps: { 'aria-label': 'rows per page' },
                              native: true,
                            }}
                            onPageChange={handleChangePage}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                          />
                        </TableRow>
                      </TableFooter>
                    </Table>
                  </TableContainer>
                </>
              ) : (
                <center>
                  <em className={'faded'}>
                    {loading ? 'Fetching data...' : 'Waiting to start...'}
                  </em>
                </center>
              )}
            </CardContent>
          </StyledCard>

          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
        </Container>
      </Body>
      <Toast
        message={
          <div style={{ cursor: 'pointer' }}>
            {message.type !== 'error' && <ToastSpinner />}{' '}
            <span
              style={{ verticalAlign: 'middle' }}
              dangerouslySetInnerHTML={{ __html: message.text }}
            />
          </div>
        }
        open={loading}
        autoHideDuration={15000}
        onClick={() => setLoading(false)}
      />
      <Footer />
    </Fragment>
  )
}
